
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('bsri_demandDIS.field_level_inspection') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('globalTrans.fiscal_year')"
                          label-for="fiscal_year_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              text="text"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('bsri_demandDIS.mill_name')"
                          label-for="mill_info_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.mill_info_id"
                              text="text"
                              :options="millInfoList"
                              id="mill_info_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.subzone_office_name')"
                          label-for="subzone_office_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.subzone_office_id"
                              text="text"
                              :options="subzoneOfficeList"
                              id="subzone_office_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_config.center_name')"
                          label-for="center_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.center_id"
                              text="text"
                              :options="centerList"
                              id="center_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.unit')"
                          label-for="unit_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.unit_id"
                              text="text"
                              :options="unitList"
                              id="unit_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
        </template>
      </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.filed_level_insepection_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(unit_id)="data">
                                            {{ $n(data.item.unit_id, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_farmers)="data">
                                            {{ $n(data.item.total_farmers, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_subsidized_land)="data">
                                            {{ $n(data.item.total_subsidized_land, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status === 1">{{$t('bsri_demandDIS.verfied')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('bsri_demandDIS.not_done')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-show variant=" iq-bg-success mr-1 mb-1" size="sm" @click="show(data.item)"><i class="ri-eye-line m-0"></i></b-button> -->
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" v-if="data.item.verfied != 1" @click="detail(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm"  @click="detail(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Detail :item="item" :key="item.id"/>
        </b-modal>
    </b-container>
</template>
<script>
import Detail from './Detail'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { SubsidyReqVerfiedList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Detail
  },
  data () {
    return {
     search: {
        fiscal_year_id: 0,
        mill_info_id: 0,
        subzone_office_id: 0,
        center_id: 0,
        unit_id: 0
      },
        fiscalYearId: '',
        rows: [],
        subzoneOfficeList: [],
        centerList: [],
        unitList: [],
        item: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.farmer_info') : this.$t('bsri_demandDIS.farmer_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.mill_name'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.subzone_office_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.center_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.unit'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.total_farmers'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.total_subsidized_land'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'mill_type_name_bn' },
          { key: 'subzone_office_name_bn' },
          { key: 'center_bn' },
          { key: 'unit_bn' },
          { key: 'total_farmers' },
          { key: 'total_subsidized_land' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'mill_type_name' },
          { key: 'subzone_office_name' },
          { key: 'center' },
          { key: 'unit' },
          { key: 'total_farmers' },
          { key: 'total_subsidized_land' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.mill_info_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.subzoneOfficeList = this.getSubZoneList(newVal)
        this.centerList = []
        this.unitList = []
      }
    },
    'search.subzone_office_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.centerList = this.getCenterList(newVal)
      }
    },
    'search.center_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.unitList = this.getUnitList(newVal)
      }
    }
  },
  created () {
   this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    show (item) {
      this.editItemId = item.id
      this.item = item
    },
    detail (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, SubsidyReqVerfiedList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item && item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList: function (centerId) {
      const unitList = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === centerId)
      return unitList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.unit_no, text: this.$n(item.text_bn) }
          } else {
              return { value: item.unit_no, text: item.text_en }
          }
      })
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
                const fiscalYearData = {}
                if (typeof fiscalYearObj !== 'undefined') {
                    fiscalYearData.fiscal_year = fiscalYearObj.text_en
                    fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
                } else {
                    fiscalYearData.fiscal_year = ''
                    fiscalYearData.fiscal_year_bn = ''
                }
            const millTypeObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millTypeList => millTypeList.value === parseInt(item.mill_info_id))
                const millTypeObjData = {}
                    if (typeof millTypeObj !== 'undefined') {
                        millTypeObjData.mill_type_name = millTypeObj.text_en
                        millTypeObjData.mill_type_name_bn = millTypeObj.text_bn
                    } else {
                        millTypeObjData.mill_type_name = ''
                        millTypeObjData.mill_type_name = ''
                    }

            const SubzoneOfficeObj = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.find(sobzoneOfficeList => sobzoneOfficeList.value === parseInt(item.subzone_office_id))
                const SubzoneOfficeData = {}
                    if (typeof SubzoneOfficeObj !== 'undefined') {
                        SubzoneOfficeData.subzone_office_name = SubzoneOfficeObj.text_en
                        SubzoneOfficeData.subzone_office_name_bn = SubzoneOfficeObj.text_bn
                    } else {
                        SubzoneOfficeData.subzone_office_name = ''
                        SubzoneOfficeData.subzone_office_name_bn = ''
                    }

            const centerListObj = this.$store.state.incentiveGrant.commonObj.centerList.find(centerList => centerList.value === parseInt(item.center_id))
                const centerListData = {}
                    if (typeof centerListObj !== 'undefined') {
                        centerListData.center = centerListObj.text_en
                        centerListData.center_bn = centerListObj.text_bn
                    } else {
                        centerListData.center = ''
                        centerListData.center_bn = ''
                    }
            const unitListObj = this.$store.state.incentiveGrant.commonObj.unitList.find(unitData => unitData.value === parseInt(item.unit_id))
                const unitListData = {}
                    if (typeof centerListObj !== 'undefined') {
                        unitListData.unit = unitListObj.text_en
                        unitListData.unit_bn = unitListObj.text_bn
                    } else {
                        unitListData.unit = ''
                        unitListData.unit_bn = ''
                    }
          return Object.assign({}, item, fiscalYearData, millTypeObjData, SubzoneOfficeData, centerListData, unitListData)
        })
        return listData
    }
  }
}
</script>
